import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Input,
  Paper,
  TableContainer,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";
import { Link, useParams } from "react-router-dom";

import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import Tables from "../../utils/components/tables";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import MultiValueInputModal from "../../utils/components/multiValueInputModal";

var columns = [
  {
    id: "certificateNumber",
    label: "Certificate Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "urlNumber",
    label: "URL Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "expandedUncertainty",
    label: "Expanded Uncertainty",
    align: "left",
    minWidth: 40,
  },
  {
    id: "calibrationDate",
    label: "Calibration Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "dueDate",
    label: "DUE Date",
    align: "left",
    minWidth: 40,
  },
];

const modalConfigs = [
  {
    buttonLabel: "Expanded Uncertainty",
    transformedStringLabel: "expandedUncertainty",
  },
];

const CalibrationRegisterHistory = () => {
  const [inputStates, setInputStates] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalRows, setTotalRows] = React.useState(0);
  const [history, setHistory] = React.useState([]);
  const _userID = localStorage.getItem("id");
  const params = useParams();

  const updateInputObject = (key, value, q) => {
    let newInputObject = {
      ...inputStates,
    };
    newInputObject[key] = value;
    setInputStates({ ...newInputObject });
  };

  const handleSubmit = () => {
    let url = BASE_URL;
    let payload = {
      calibrationId: params.calibrationId,
      calibrationDate: inputStates.calibrationDate
        ? moment(inputStates.calibrationDate).format("YYYY-MM-DD")
        : null,
      dueDate: inputStates.dueDate
        ? moment(inputStates.dueDate).format("YYYY-MM-DD")
        : null,
      certificateNumber: inputStates.certificateNumber,
      urlNumber: inputStates.urlNumber,
      expandedUncertainty: inputStates.expandedUncertainty,
      filePath: inputStates.filePath,
      createdBy: _userID,
    };

    let payload1 = {
      calibrationDate: inputStates.calibrationDate
        ? moment(inputStates.calibrationDate).format("YYYY-MM-DD")
        : null,
      dueDate: inputStates.dueDate
        ? moment(inputStates.dueDate).format("YYYY-MM-DD")
        : null,
      certificateFilePath: inputStates.filePath,
      certificateNumber: inputStates.certificateNumber,
      urlNumber: inputStates.urlNumber,
      certificateApprove: null,
      createdBy: _userID,
    };

    axiosWithToken
      .post(url + "calibration_register_history", payload)
      .then((res) => {
        axiosWithToken
          .patch(url + `calibration_register/${params.calibrationId}`, payload1)
          .then((res1) => {
            setTimeout(refresh, 500);
            toast("Calibration  created successfully !");
          });
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const fetchCalibrationHistory = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          `calibration_register_history?_where=(calibrationId,eq,${params.calibrationId})&_sort=-id`
      )
      .then((res) => {
        let dataObject = res.data;

        dataObject && setHistory(dataObject);
        setTotalRows(dataObject?.length);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append("fileToUpload", file);
    axiosWithToken
      .post(BASE_URL + `fileUpload/fileUpload.php`, data)
      .then((res) => {
        if (res.status === 200) {
          // setpoFileUrl(res.data);
          updateInputObject("filePath", res.data);
          toast.success("File Uploaded Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something Went Wrong!");
      });
  };

  const handleDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `calibration_register_history/${id}`)
      .then((res) => {
        toast("calibration_register_history is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (params.calibrationId) fetchCalibrationHistory();
  }, [params.calibrationId]);

  const actions = [
    {
      id: "delete",
      tooltip: "Delete Calibration",
      icon: <DeleteIcon style={{ color: "#dc3545" }} />,
      handler: (row) =>
        window.confirm("Are you sure you want to delete this?") &&
        handleDelete(row?.id),
    },

    {
      id: "viewCertificate",
      tooltip: "View Certificate",
      icon: <PreviewIcon />,
      handler: (row) => {
        if (row.filePath) {
          window.open(row.filePath, "_blank");
        }
      },
    },
  ];

  return (
    <div>
      <div>
        <Paper sx={{ mt: 2, p: 2 }}>
          <Box style={{ display: "flex", marginBottom: "5px" }}>
            <Typography variant="h6" component="h6" style={{ float: "left" }}>
              Calibration Register History
            </Typography>
            {history[0]?.dueDate &&
              moment(history[0]?.dueDate).isAfter(moment()) && (
                <div
                  style={{
                    border: "1px solid gray",
                    width: "60%",
                    margin: "auto",
                    backgroundColor: "#e9c2e9",
                  }}
                >
                  <h6 style={{ fontSize: "18px" }}>
                    {`Instrument is Active, Calibrate Before ${moment(
                      history[0]?.dueDate
                    ).format("DD/MM/YYYY")}`}
                  </h6>
                </div>
              )}
          </Box>

          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="Certificate Number *"
                size="small"
                value={inputStates.certificateNumber || ""}
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("certificateNumber", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="URL Number *"
                size="small"
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                value={inputStates.urlNumber || ""}
                onChange={(e) => {
                  updateInputObject("urlNumber", e.target.value);
                }}
              />
            </Grid>

            {/* <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="Expanded Uncertainty"
                size="small"
                value={inputStates.expandedUncertainty || ""}
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("expandedUncertainty", e.target.value);
                }}
              />
            </Grid> */}

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div style={{ display: "flex", gap: "50px", flexWrap: "wrap" }}>
                {modalConfigs.map((config, index) => (
                  <MultiValueInputModal
                    key={index}
                    buttonLabel={config.buttonLabel}
                    transformedStringLabel={config.transformedStringLabel}
                    inputStates={inputStates}
                    updateInputObject={updateInputObject}
                  />
                ))}
              </div>
            </Grid>

            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  label="Calibration Date *"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={
                    inputStates.calibrationDate
                      ? new Date(inputStates.calibrationDate)
                      : null
                  }
                  onChange={(newValue) => {
                    updateInputObject(
                      "calibrationDate",
                      moment(newValue).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  label="DUE Date *"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={
                    inputStates.dueDate ? new Date(inputStates.dueDate) : null
                  }
                  onChange={(newValue) => {
                    updateInputObject(
                      "dueDate",
                      moment(newValue).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3}>
              <Input
                id="file-upload-input"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => handleFileUpload(e)}
              />
              <label htmlFor="file-upload-input">
                <Button
                  variant="contained"
                  component="span"
                  disabled={
                    history[0]?.dueDate &&
                    !moment().isAfter(
                      moment(history[0]?.dueDate).subtract(7, "days")
                    )
                  }
                >
                  Upload Certificate
                </Button>
              </label>
              <Typography variant="body2" color="textSecondary">
                Select a file to upload
              </Typography>
            </Grid>
          </Grid>
          <Toolbar style={{ padding: "0px", overflow: "auto" }}>
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              disabled={
                history[0]?.dueDate &&
                !moment().isAfter(
                  moment(history[0]?.dueDate).subtract(7, "days")
                )
              }
              onClick={() => {
                handleSubmit();
              }}
            >
              {params.id ? "Update" : "Save"}
            </Button>
          </Toolbar>
        </Paper>
      </div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <br />
        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleDelete={handleDelete}
          columns={columns}
          finalData={history}
          actions={actions}
          totalRows={totalRows}
        />
      </TableContainer>
    </div>
  );
};

export default CalibrationRegisterHistory;
