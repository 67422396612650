import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
  Toolbar,
} from "@mui/material";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "./../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";

const textStyle = {
  fontWeight: "bold",
  display: "inline-block",
  minWidth: "120px",
};

const tableStyle = {
  backgroundColor: "#1976d2",
  color: "white",
  fontWeight: "bold",
};

const ViewLogs = () => {
  const location = useLocation();
  const [auditData, setAuditData] = useState([]);
  const [tableDataArray, setTableDataArray] = useState([]);
  const printComponentRef = React.useRef();
  const [downloadPdfloading, setDownlaodPdfLoading] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );

  const queryIdArray = new URLSearchParams(location.search).get("id");
  let AuditIDArray = JSON.parse(queryIdArray);

  const getData = async (auditId) => {
    try {
      let data = {
        query: `SELECT al.*,  u.userName FROM audit_log al  LEFT JOIN users u ON al.userId = u.id where al.id=${auditId}`,
      };
      const res = await axiosWithToken.post(BASE_URL + `dynamic`, data);

      // Parse old_value and new_value fields
      const parsedData = res.data.map((item) => ({
        ...item,
        old_value: JSON.parse(item.old_value),
        new_value: JSON.parse(item.new_value),
      }));

      return parsedData;
    } catch (err) {
      console.log("Data fetching error: ", err);
      throw err;
    }
  };

  useEffect(() => {
    const fetchDataForAllIds = async () => {
      const promises = AuditIDArray.map((auditId) => getData(auditId));

      try {
        const results = await Promise.all(promises);
        setAuditData(results.flat());
      } catch (err) {
        console.log("error", err);
      }
    };

    if (AuditIDArray && AuditIDArray.length > 0) {
      fetchDataForAllIds();
    }
  }, []);

  const generateTableData = (auditData) => {
    const newTableDataArray = [];

    for (const item of auditData) {
      const changeArray = [];

      if (item.new_value) {
        for (const key in item.new_value) {
          changeArray.push({
            name: key,
            preValue: item.old_value ? item.old_value[key] || null : null,
            latestValue: item.new_value[key] || null,
          });
        }
      }

      newTableDataArray.push(changeArray);
    }

    return newTableDataArray;
  };

  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.9);
    document.body.removeChild(div);
    return img;
  };

  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:170px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.9);
    document.body.removeChild(div);
    return img;
  };

  const generatePDF = async () => {
    setDownlaodPdfLoading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [70, 0, 60, 0],
          filename: `AuditData_.pdf`,
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 2.5,
            letterRendering: true,
            useCORS: true,
          },
          image: { type: "png", quality: 0.4 },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
            compress: true,
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("Courier");

        // border add for contnet
        // const borderWidth = 0.6;
        // const leftMargin = 15;
        // const borderX = leftMargin;
        // const borderY = 30;
        // const rightMargin = 595 - leftMargin * 0.95;
        // pdf.setLineWidth(borderWidth);
        // pdf.rect(borderX, borderY, rightMargin - leftMargin, 800);
        let image = await generateLetterHeadHeader();

        pdf.addImage(image, 0, 0, 595, 70);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 790, 595, 50);
      }
      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `AuditData.pdf`;
      a.click();

      setDownlaodPdfLoading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownlaodPdfLoading(false);
    }
  };

  useEffect(() => {
    setTableDataArray(generateTableData(auditData));
  }, [auditData]);

  return (
    <>
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginRight: 10,
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          disabled={!(editAccess?.includes(3) || editAccess?.includes(0))}
          onClick={() => {
            generatePDF();
          }}
        >
          Download PDF
        </Button>
      </Toolbar>
      <div ref={printComponentRef}>
        <div
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {tableDataArray?.map((tableData, tableIndex) => (
            <div
              key={tableIndex}
              style={{
                marginTop: "30px",
                paddingBottom: "50px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Divider sx={{ borderTop: "2px solid" }} />
              <Typography
                variant="h6"
                sx={{ textAlign: "left", margin: "10px", fontWeight: "bold" }}
                color="initial"
              >
                Table of {auditData[tableIndex].type}
              </Typography>
              <Paper
                elevation={3}
                style={{
                  padding: "20px",
                  marginBottom: "20px",
                  width: "40%",
                  minWidth: "585px",
                  //  margin: "auto",
                  textAlign: "left",
                }}
              >
                <Typography variant="h6">
                  <span style={textStyle}>Type:</span>{" "}
                  {auditData[tableIndex].type}
                </Typography>
                <Typography variant="h6">
                  <span style={textStyle}>Identifier:</span>{" "}
                  {auditData[tableIndex].identifier}
                </Typography>
                <Typography variant="h6">
                  <span style={textStyle}>User:</span>{" "}
                  {auditData[tableIndex].userName}
                </Typography>
                <Typography variant="h6">
                  <span style={textStyle}>Time:</span>{" "}
                  {moment(auditData[tableIndex].lastModified).format(
                    "DD-MM-YYYY hh:mm A"
                  )}
                </Typography>

                <Typography variant="h6">
                  <span style={textStyle}>Action:</span>{" "}
                  {auditData[tableIndex].action === "insert"
                    ? "Create"
                    : auditData[tableIndex].action}
                </Typography>
              </Paper>

              {tableData.length > 0 && (
                <Table
                  stickyHeader
                  aria-label={`sticky table ${tableIndex}`}
                  className="table-responsive"
                  size="small"
                  style={{ marginTop: "50px" }}
                  id={`datatable-keytable-${tableIndex}`}
                >
                  <TableHead>
                    <TableRow style={tableStyle}>
                      <TableCell style={tableStyle}>Column Name</TableCell>
                      {auditData[tableIndex].action !== "insert" && (
                        <TableCell style={tableStyle}>Previous Value</TableCell>
                      )}
                      <TableCell style={tableStyle}>
                        {auditData[tableIndex].action == "insert"
                          ? `Value`
                          : `Updated Value`}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData?.map((column, columnIndex) =>
                      column?.preValue !== column?.latestValue ? (
                        <TableRow>
                          <TableCell>{column?.name}</TableCell>
                          {auditData[tableIndex].action !== "insert" && (
                            <TableCell>
                              {typeof column?.preValue === "string"
                                ? column?.preValue
                                    .replaceAll("||", ",")
                                    .replaceAll("|", " to ")
                                    .replaceAll("#", "  ")
                                : column?.preValue}
                            </TableCell>
                          )}
                          <TableCell>
                            {typeof column?.latestValue === "string"
                              ? column?.latestValue
                                  .replaceAll("||", ",")
                                  .replaceAll("|", " to ")
                                  .replaceAll("#", "  ")
                              : column?.latestValue}
                          </TableCell>
                        </TableRow>
                      ) : null
                    )}
                  </TableBody>
                </Table>
              )}
            </div>
          ))}
        </div>
        <div style={{ width: "100%" }} id="header-section"></div>
      </div>
      {downloadPdfloading && (
        <DownloadPdfLoaderModal
          loading={downloadPdfloading}
          name={"Audit Log"}
        />
      )}
    </>
  );
};

export default ViewLogs;
