import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Input,
  Modal,
  Paper,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { notifiy } from "../notification/Notification";
import MultiValueInputModal from "../../utils/components/multiValueInputModal";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid cadetblue",
  padding: "20px",
  textAlign: "center",
  borderRadius: "5px",
};

const modalConfigs = [
  {
    buttonLabel: "Expanded Uncertainty",
    transformedStringLabel: "expandedUncertainty",
  },
  
];

const EditCalibrationRegister = () => {
  const [inputStates, setInputStates] = React.useState({
    certificateApprove: null,
  });
  const [instrumentList, setInstrumentList] = React.useState([]);
  const [calibrationFrequency, setCalibrationFrequency] = React.useState(null);
  const [dentificationNumbertData, setIdentificationNumbertData] =
    React.useState([]);
  const [vendorMasterData, setVendorMasterData] = React.useState([]);
  const [allInstrument_masterData, setAllInstrument_masterData] =
    React.useState([]);
  const [rejectReason, setRejectReason] = React.useState("");
  const [vendorsClientId, setVendorsClientId] = React.useState(null);
  const [foundCal, setFoundCal] = React.useState(0);

  const [branch, setBranch] = React.useState(localStorage.getItem("branch"));
  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );
  const _clientId = localStorage.getItem("clientId");
  const _vendorId = localStorage.getItem("vendorId");
  const userTypeIs = localStorage.getItem("type");
  const [modalOpen, setModalOpen] = React.useState(false);
  const _userID = localStorage.getItem("id");

  const params = useParams();

  const updateInputObject = (key, value, q) => {
    let newInputObject = {
      ...inputStates,
    };
    newInputObject[key] = value;
    setInputStates({ ...newInputObject });
  };

  const handleSubmit = async () => {
    const url = `${BASE_URL}calibration_register`;
    const formatDate = (date) =>
      date ? moment(date).format("YYYY-MM-DD") : null;

    const payload = {
      instrumentId: inputStates.instrumentId,
      calibrationDate: formatDate(inputStates.calibrationDate),
      dueDate: formatDate(inputStates.dueDate),
      certificateNumber: inputStates.certificateNumber,
      identificationNumber: inputStates.identificationNumber,
      urlNumber: inputStates.urlNumber,
      // description: inputStates.description,
      remark: inputStates.remark,
      category: inputStates.category,
      nablScope: inputStates.nablScope,
      VendorId: inputStates.VendorId,
      expandedUncertainty: inputStates.expandedUncertainty,
      certificateFilePath: inputStates.certificateFilePath,
      createdBy : _userID,
    };

    try {
      if (params.id) {
        await axiosWithToken.patch(`${url}/${params.id}`, payload);
        toast("Calibration edited successfully!");
      } else {
        const response = await axiosWithToken.post(url, inputStates);
        const historyPayload = {
          calibrationId: response.data.insertId,
          calibrationDate: formatDate(inputStates.calibrationDate),
          dueDate: formatDate(inputStates.dueDate),
          certificateNumber: inputStates.certificateNumber,
          urlNumber: inputStates.urlNumber,
          expandedUncertainty: inputStates.expandedUncertainty,
          filePath: inputStates.certificateFilePath,
          createdBy : _userID,
        };

        await axiosWithToken.post(`${url}_history`, historyPayload);

        const users = await axiosWithToken.get(
          `${BASE_URL}users?_where=(status,eq,1)~and((type,eq,1)~or((type,eq,4)${
            branch ? `~and(branch,eq,${branch})` : ""
          }))&fields=id`
        );

        // users.data.forEach((user) =>
        //   notifiy({
        //     _from: user[0],
        //     _to: user.id,
        //     body: `This calibration was created by ${user[1]}. The ID of that calibration is ${response.data.insertId}.`,
        //   })
        // );

        toast("Calibration created successfully!");
      }
    } catch (error) {
      toast.error("Something Went Wrong!");
    } finally {
      setTimeout(refresh, 500);
    }
  };

  const fetchCalibration_register = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `calibration_register/${params.id}`)
      .then((res) => {
        let dataObject = res.data[0];

        let newData = {
          ...inputStates,
          ...dataObject,
        };
        dataObject && setInputStates(newData);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const fetchAllInstrument_master = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url + `instrument_master?_fields=id,identificationNumber,instrumentId`
      )
      .then((res) => {
        let dataObject = res.data;
        setAllInstrument_masterData(dataObject);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  function getInstrumentData() {
    let payload = {
      query: `SELECT DISTINCT instrument.*, instrument_master.approvedby FROM instrument JOIN instrument_master ON instrument.id = instrument_master.instrumentId LEFT JOIN instrument_status ON instrument.id = instrument_status.instrumentId WHERE (instrument_status.instrumentId IS NULL OR NOT EXISTS (SELECT 1 FROM instrument_status WHERE instrument_status.instrumentId = instrument.id AND instrument_status.identificationNumber = instrument_master.identificationNumber)) AND instrument_master.approvedby IS NOT NULL ${
        userTypeIs == 3
          ? `AND instrument_master.assignedUser = ${_vendorId}`
          : ""
      }`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, payload)
      .then((res) => {
        let newData = res.data;
        setInstrumentList(newData);
      })
      .catch((err) => {
        console.log("instrument data fetching error: ", err);
      });
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append("fileToUpload", file);
    axiosWithToken
      .post(BASE_URL + `fileUpload/fileUpload.php`, data)
      .then((res) => {
        if (res.status === 200) {
          // setpoFileUrl(res.data);
          updateInputObject("certificateFilePath", res.data);
          toast.success("File Uploaded Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something Went Wrong!");
      });
  };

  function getIdentificationNumbertData() {
    let payload = {
      query: `SELECT identificationNumber, id,calibrationFrequency,assignedUser, instrumentId FROM instrument_master WHERE instrumentId = ${
        inputStates.instrumentId
      } AND NOT EXISTS (SELECT 1 FROM instrument_status WHERE instrument_status.instrumentId = instrument_master.instrumentId AND instrument_status.identificationNumber = instrument_master.identificationNumber) and instrument_master.id IN (SELECT instrumentMasterId FROM instrumentInOut WHERE actualReturnDate IS NULL OR actualReturnDate > CURRENT_DATE) ${
        userTypeIs == 3 && !params.id
          ? `AND NOT EXISTS (SELECT 1 FROM calibration_register WHERE calibration_register.instrumentId = instrument_master.instrumentId AND calibration_register.identificationNumber = instrument_master.identificationNumber) AND assignedUser = ${_vendorId}`
          : ""
      }`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, payload)
      .then((res) => {
        let newData = res.data;
        setIdentificationNumbertData(newData);
      })
      .catch((err) => {
        console.log("instrument data fetching error: ", err);
      });
  }

  function getVendorMasterData() {
    axiosWithToken
      .get(BASE_URL + `vendor_master`)
      .then((res) => {
        let newData = res.data;
        setVendorMasterData(newData);
      })
      .catch((err) => {
        console.log("instrument data fetching error: ", err);
      });
  }

  function getVendorsClientId() {
    let SQLQuery = {
      query: `select userName, id from users where vendorId = ${inputStates.VendorId}`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, SQLQuery)
      .then((res) => {
        let newData = res.data?.[0];
        setVendorsClientId(newData?.id);
      })
      .catch((err) => {
        console.log("getVendorsClientId data fetching error: ", err);
      });
  }

  var refresh = () => {
    window.location.reload(false);
  };

  const handleClose = () => {
    setModalOpen(false);
  };


  const checkCal = async()=>{
    const payload = {
      query: `
        SELECT COUNT(*) AS count 
        FROM calibration_register 
        WHERE instrumentId = '${inputStates.instrumentId}' 
          AND identificationNumber = '${inputStates.identificationNumber}' 
          AND (dueDate IS NULL OR dueDate > CURRENT_DATE)
      `,
    };

    try {
      const response = await axiosWithToken.post(BASE_URL + `dynamic`, payload);
      const count = response.data?.[0]?.count || 0;
      setFoundCal(count)

    } catch (error) {
      console.log("Instrument data fetching error: ", error);
      // toast.error("Error fetching data from calibration_register");
     
    }
  }

  const validateData = async () => {
    let isValid = true;
  
    if (!inputStates?.instrumentId) {
      toast.error("Please select an Instrument");
      isValid = false;
    }
  
    if (!inputStates?.identificationNumber) {
      toast.error("Please Enter Identification Number");
      isValid = false;
    }
  
    if (!inputStates?.calibrationDate) {
      toast.error("Please Select Calibration Date");
      isValid = false;
    }
  
    if (!inputStates?.dueDate) {
      toast.error("Please Select Due Date");
      isValid = false;
    }
  
    if (foundCal > 0) {
      toast.error(
        "Calibration record found "
      );
      isValid = false;
    }
  
    return isValid;
  };
  

  const approveCertificate = () => {
    let SQL_Query = {
      query: `UPDATE calibration_register SET certificateApprove = ${_userID} WHERE id = ${params.id}`,
    };

    // Find the object
    const foundInstrument = allInstrument_masterData.find(
      (instrument) =>
        instrument.instrumentId == inputStates.instrumentId &&
        instrument.identificationNumber == inputStates.identificationNumber
    );

    axiosWithToken
      .post(BASE_URL + `dynamic`, SQL_Query)
      .then((response) => {
        let SQL_Query = {
          query: `UPDATE instrument_master SET assignedUser = null WHERE id = ${foundInstrument?.id}`,
        };
        axiosWithToken.post(BASE_URL + `dynamic`, SQL_Query);

        setTimeout(refresh, 500);
        toast("Certificate approve successfully !");
      })
      .catch((err) => {
        console.log("Certificate approve error: ", err);
      });
  };

  const rejectCertificate = () => {
    let SQL_Query = {
      query: `UPDATE calibration_register SET certificateApprove = 0 WHERE id = ${params.id}`,
    };

    let idNumber =
      dentificationNumbertData.find(
        (lab) => lab.identificationNumber === inputStates.identificationNumber
      )?.identificationNumber || null;

    let instrumentName =
      instrumentList.find((lab) => lab.id === inputStates.instrumentId)?.name ||
      null;

    axiosWithToken
      .post(BASE_URL + `dynamic`, SQL_Query)
      .then((response) => {
        const notification = {
          _from: _userID,
          _to: vendorsClientId,
          body: `${instrumentName}(${idNumber}) certificate rejected (Admin) :\n ${rejectReason}`,
          type: 1,
        };
        notifiy(notification, () => {
          console.log(`Notified of rejection to ${inputStates.VendorId}`);
        });
        setTimeout(refresh, 500);
        toast("Certificate reject successfully !");
      })
      .catch((err) => {
        console.log("Certificate reject error: ", err);
      });
  };

  useEffect(() => {
    if (params.id) fetchCalibration_register();
    if (params.id) fetchAllInstrument_master();
  }, [params.id]);

  useEffect(() => {
    getInstrumentData();
    getVendorMasterData();
  }, []);

  useEffect(() => {
    if (inputStates.VendorId) getVendorsClientId();
  }, [inputStates.VendorId]);

  useEffect(() => {
    if (inputStates.instrumentId) getIdentificationNumbertData();
  }, [inputStates.instrumentId]);

  useEffect(() => {
    if (inputStates.instrumentId && inputStates?.identificationNumber) checkCal();
  }, [inputStates.instrumentId, inputStates?.identificationNumber]);

  return (
    <div>
      <div>
        <Paper sx={{ mt: 2, p: 2 }}>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            Create/Update Calibration Register
          </Typography>
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={instrumentList}
                disabled={params.id}
                getOptionLabel={(option) => option.name}
                value={
                  instrumentList.find(
                    (lab) => lab.id === inputStates.instrumentId
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Instrument Name *" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("instrumentId", value.id);
                  } else {
                    updateInputObject("instrumentId", "");
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={dentificationNumbertData}
                disabled={params.id}
                getOptionLabel={(option) => option.identificationNumber}
                value={
                  dentificationNumbertData.find(
                    (lab) =>
                      lab.identificationNumber ===
                      inputStates.identificationNumber
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Identification Number *" />
                )}
                onChange={(event, value) => {
                  setCalibrationFrequency(value?.calibrationFrequency || "");

                  setInputStates((prevState) => ({
                    ...prevState,
                    identificationNumber: value?.identificationNumber || "",
                    VendorId: value?.assignedUser || "",
                  }));
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  label="Calibration Date *"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={
                    inputStates.calibrationDate
                      ? new Date(inputStates.calibrationDate)
                      : null
                  }
                  onChange={(newValue) => {
                    if (newValue) {
                      const formattedDate =
                        moment(newValue).format("YYYY-MM-DD");
                      let dueDate = null;

                      if (calibrationFrequency) {
                        const [freqValue] = calibrationFrequency.split(" ");
                        dueDate = moment(newValue)
                          .add(Number(freqValue), "M")
                          .format("YYYY-MM-DD");
                      }

                      setInputStates((prevState) => ({
                        ...prevState,
                        calibrationDate: formattedDate,
                        dueDate: dueDate,
                      }));
                    } else {
                      setInputStates((prevState) => ({
                        ...prevState,
                        calibrationDate: null,
                        dueDate: null,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  label="DUE Date *"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={
                    inputStates.dueDate ? new Date(inputStates.dueDate) : null
                  }
                  onChange={(newValue) => {
                    updateInputObject(
                      "dueDate",
                      moment(newValue).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="outlined-basic"
                label="Certificate Number *"
                size="small"
                value={inputStates.certificateNumber || ""}
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("certificateNumber", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="outlined-basic"
                label="URL Number *"
                size="small"
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                value={inputStates.urlNumber || ""}
                onChange={(e) => {
                  updateInputObject("urlNumber", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              {/* <TextField
                id="outlined-basic"
                label="Expanded Uncertainty"
                size="small"
                value={inputStates.expandedUncertainty || ""}
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("expandedUncertainty", e.target.value);
                }}
              /> */}

<div style={{ display: "flex", gap: "50px", flexWrap: "wrap" }}>
                {modalConfigs.map((config, index) => (
                  <MultiValueInputModal
                    key={index}
                    buttonLabel={config.buttonLabel}
                    transformedStringLabel={config.transformedStringLabel}
                    inputStates={inputStates}
                    updateInputObject={updateInputObject}
                  />
                ))}
              </div>
            </Grid>

            {/* ??>>><<<?? */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="outlined-basic"
                label="Category"
                size="small"
                value={inputStates.category || ""}
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("category", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="outlined-basic"
                label="Remark"
                size="small"
                multiline
                rows={4} 
                value={inputStates.remark || ""}
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("remark", e.target.value);
                }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="outlined-basic"
                label="Category"
                size="small"
                value={inputStates.category || ""}
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("category", e.target.value);
                }}
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                disabled={params.invoiceId}
                size="small"
                id="combo-box-demo"
                options={
                  userTypeIs == 3
                    ? vendorMasterData?.filter(
                        (vendor) => vendor.id == _vendorId
                      )
                    : vendorMasterData
                }
                getOptionLabel={(option) => option.name}
                value={
                  vendorMasterData.find(
                    (lab) => lab.id === inputStates.VendorId
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Vendor Name " />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("VendorId", value.id);
                  } else {
                    updateInputObject("VendorId", "");
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={inputStates.nablScope == 1}
                    onChange={(e) => {
                      const value = e.target.checked ? 1 : 0;
                      updateInputObject("nablScope", value);
                    }}
                    color="primary"
                  />
                }
                label="NABLscope"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}></Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Input
                id="file-upload-input"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => handleFileUpload(e)}
              />
              <label htmlFor="file-upload-input">
                <Button variant="contained" component="span">
                  Upload Certificate
                </Button>
              </label>
              <Typography variant="body2" color="textSecondary">
                Select a file to upload
              </Typography>
            </Grid>
          </Grid>
          <Toolbar style={{ padding: "0px", overflow: "auto" }}>
            <Button
              variant="contained"
              disabled={
                (inputStates?.certificateApprove !== null &&
                  inputStates?.certificateApprove != 0) ||
                (userTypeIs != 3 &&
                  !(editAccess?.includes(1) || editAccess?.includes(0)))
              }
              size="small"
              sx={{ m: 0, ml: "auto", px: 4 }}
              onClick={async () => {
                if (!(await validateData())) return;
                handleSubmit();
              }}
            >
              {params.id ? "Update" : "Save"}
            </Button>
            {(inputStates?.certificateApprove == null ||
              inputStates?.certificateApprove == 0) &&
              params?.id &&
              userTypeIs != 3 && (
                <>
                  <Button
                    id="certificate-certificateview_Approvebtn"
                    variant="contained"
                    color="success"
                    size="small"
                    sx={{ ml: 3 }}
                    onClick={() => {
                      approveCertificate();
                    }}
                  >
                    Approve
                  </Button>

                  <Button
                    id="certificate-certificateview_Rejectbtn"
                    variant="contained"
                    color="error"
                    size="small"
                    sx={{ ml: 3 }}
                    onClick={() => {
                      setModalOpen(true);
                    }}
                  >
                    Reject
                  </Button>
                </>
              )}
          </Toolbar>
        </Paper>
      </div>
      <Modal
        open={modalOpen}
        onClose={(e, r) => {
          handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={modalStyle}>
          <TextField
            id="outlined-basic"
            label="Reason For Rejection"
            value={rejectReason}
            multiline
            maxRows={5}
            rows={5}
            size="small"
            fullWidth
            variant="outlined"
            style={{ marginBottom: "5px" }}
            onChange={(e) => setRejectReason(e.target.value)}
          />
          <Tooltip title="Send Rejection Reason" placement="top-start">
            <Button
              variant="contained"
              color="error"
              size="small"
              sx={{ m: 2 }}
              onClick={() => {
                rejectCertificate();
              }}
              disabled={rejectReason === ""}
            >
              Reject
            </Button>
          </Tooltip>
        </Box>
      </Modal>
    </div>
  );
};

export default EditCalibrationRegister;
