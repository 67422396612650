import {
  Autocomplete,
  Button,
  Grid,
  Input,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

const StatusArray = [
  { id: 1, name: "Missing" },
  { id: 2, name: "Scrapped" },
];

const EditInstrumentStatus = () => {
  const [inputStates, setInputStates] = React.useState({});
  const [instrumentList, setInstrumentList] = React.useState([]);
  const [identificationNumberList, setidentificationNumberList] =
    React.useState([]);
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );

  const params = useParams();


  const updateInputObject = (key, value, q) => {
    let newInputObject = {
      ...inputStates,
    };
    newInputObject[key] = value;
    setInputStates({ ...newInputObject });
  };

  const handleSubmit = () => {
    let url = BASE_URL;
    let payload = {
      instrumentId: inputStates.instrumentId,
      date: inputStates.date
        ? moment(inputStates.date).format("YYYY-MM-DD")
        : null,
      identificationNumber: inputStates.identificationNumber,
      status: inputStates.status,
      reason: inputStates.reason,
      instrument_masterId: inputStates.instrument_masterId,
      correctiveAction: inputStates.correctiveAction,
    };
    if (params.id) {
      axiosWithToken
        .patch(url + `instrument_status/${params.id}`, payload)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Instrument_status  edit successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    } else {
      axiosWithToken
        .post(url + "instrument_status", payload)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("instrument_status  created successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const fetchInstrument_status = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `instrument_status/${params.id}`)
      .then((res) => {
        let dataObject = res.data[0];

        let newData = {
          ...inputStates,
          ...dataObject,
        };
        dataObject && setInputStates(newData);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  function getInstrumentData() {
    let paylod = {
      query:
        "SELECT * FROM instrument ",
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, paylod)
      .then((res) => {
        let newData = res.data;
        setInstrumentList(newData);
      })
      .catch((err) => {
        console.log("instrument_master data fetching error: ", err);
      });
  }
  function getIdentificationNumberData() {
    let payload = {
      query: `SELECT * FROM instrument_master im WHERE im.instrumentId = ${inputStates?.instrumentId} ${!params.id ? 'AND im.id NOT IN (SELECT instrument_masterId FROM instrument_status)' : ''} `,
    };
    
    //AND NOT EXISTS (SELECT 1 FROM instrument_status ist WHERE ist.instrumentId = im.instrumentId AND ist.identificationNumber = im.identificationNumber)
    
    axiosWithToken
      .post(BASE_URL + `dynamic`, payload)
      .then((res) => {
        let newData = res.data;
        setidentificationNumberList(newData);
      })
      .catch((err) => {
        console.log("instrument_master data fetching error: ", err);
      });
  }

  var refresh = () => {
    window.location.reload(false);
  };

  const validateData = () => {
    let isValid = true;

    if (!inputStates?.instrumentId) {
      toast.error("Please select a Instrument");
      isValid = false;
    }
    if (!inputStates?.identificationNumber) {
      toast.error("Please  Enter Identification Number");
      isValid = false;
    }
    return isValid;
  };

  useEffect(() => {
    if (params.id) fetchInstrument_status();
  }, [params.id]);

  useEffect(() => {
    if (inputStates?.instrumentId) getIdentificationNumberData();
  }, [inputStates?.instrumentId]);

  useEffect(() => {
    getInstrumentData();
  }, []);

  return (
    <div>
      <div>
        <Paper sx={{ mt: 2, p: 2 }}>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            Create/Update Instrument Status
          </Typography>
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                disabled={params.id}
                size="small"
                id="combo-box-demo"
                options={instrumentList}
                getOptionLabel={(option) => option.name}
                value={
                  instrumentList.find(
                    (lab) => lab.id === inputStates.instrumentId
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Instrument Name *" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("instrumentId", value.id);
                    // updateInputObject("instrumentName", value.name);
                  } else {
                    updateInputObject("instrumentId", "");
                    // updateInputObject("instrumentName", "");
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                // disabled={params.invoiceId}
                size="small"
                id="combo-box-demo"
                disabled={params.id}
                options={identificationNumberList}
                getOptionLabel={(option) => option.identificationNumber}
                value={
                  identificationNumberList.find(
                    (lab) =>
                      lab.identificationNumber ==
                      inputStates.identificationNumber
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Identification Number*" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    setInputStates((prev) => ({
                      ...prev,
                      identificationNumber: value.identificationNumber,
                      instrument_masterId : value.id,
                    }));
                    // updateInputObject(
                    //   "identificationNumber",
                    //   value.identificationNumber
                    // );
                  } else {
                    updateInputObject("identificationNumber", "");
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  label=" Date *"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={inputStates.date ? new Date(inputStates.date) : null}
                  onChange={(newValue) => {
                    updateInputObject(
                      "date",
                      moment(newValue).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                disabled={params.invoiceId}
                size="small"
                id="combo-box-demo"
                options={StatusArray}
                getOptionLabel={(option) => option.name}
                value={
                  StatusArray.find((lab) => lab.id === inputStates.status) ||
                  null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Status *" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("status", value.id);
                  } else {
                    updateInputObject("status", "");
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                label="Reason *"
                size="small"
                rows={3}
                multiline
                maxRows={5}
                value={inputStates.reason || ""}
                fullWidth
                inputProps={{
                  autoComplete: 'off',
                }}                
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("reason", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                label="Corrective Action *"
                size="small"
                fullWidth
                inputProps={{
                  autoComplete: 'off',
                }}                
                rows={3}
                multiline
                maxRows={5}
                variant="outlined"
                value={inputStates.correctiveAction || ""}
                onChange={(e) => {
                  updateInputObject("correctiveAction", e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Toolbar style={{ padding: "0px", overflow: "auto" }}>
            <Button
              variant="contained"
              size="small"
              disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
              sx={{ m: 0, ml: "auto", px: 4 }}
              onClick={() => {
                if (!validateData()) return;
                handleSubmit();
              }}
            >
              {params.id ? "Update" : "Save"}
            </Button>
          </Toolbar>
        </Paper>
      </div>
    </div>
  );
};

export default EditInstrumentStatus;
